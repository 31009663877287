<template>
  <span class="icon has-text-grey">
    <svg width="36" height="36" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
      <path
          d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
          stroke="currentColor"
          stroke-width="1"
          fill="none"
      />
    </svg>
  </span>
</template>
<script>
export default {
};
</script>
<template>
  <span class="icon has-text-grey">
    <svg width="16" height="16" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
      <path
          d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
          stroke="currentColor"
          stroke-width="0.8"
          fill="none"
      />
      <path
          d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"
          stroke="currentColor"
          stroke-width="1"
          fill="none"
      />
    </svg>
  </span>
</template>
<script>
export default {
};
</script>
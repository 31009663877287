<template>
  <span class="icon" :class="color">
    <svg :width="size" :height="size" fill="currentColor" class="bi bi-chevron-expand" viewBox="0 0 16 16">
      <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5"
          stroke="currentColor"
          fill-rule="evenodd"
          stroke-width="1"
      />
    </svg>
  </span>
</template>
<script>
export default {
  props: {
      color: {
        type: String,
        default: "has-text-grey"
      },
      size: {
        type: Number,
        default: 16
      }
  }
};
</script>
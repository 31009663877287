<template>
  <icon
      class="has-text-grey hover-bold"
      :class="{ [rotationDegreesClass]: rotate, 'rotate-back': !rotate }"
      :icon="iconClass"
  />
</template>

<script>
export default {
  props: {
    rotate: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "chevron-right",
    },
    rotationDegrees: {
      type: Number,
      default: 90,
    },
  },
  computed: {
    rotationDegreesClass() {
      switch (this.rotationDegrees) {
        case 90:
          return 'rotate-90';
        case 180:
          return 'rotate-180';
        case -180:
          return 'rotate-reverse-180';
        default:
          return 'rotate-90';
      }
    },
  },
};
</script>

<style>
.rotate-90 {
  transition: transform 0.2s ease-in-out;
  transform: rotate(90deg);
  color: #696969 !important;
}

.rotate-180 {
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
}

.rotate-reverse-180 {
  transition: transform 0.2s ease-in-out;
  transform: rotate(-180deg);
}

.rotate-back {
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
}
</style>

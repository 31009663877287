<template>
  <span class="icon" :class="color">
    <svg :width="size" :height="size" fill="currentColor" class="bi bi-chevron-expand" viewBox="0 0 16 16">
      <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
          stroke="currentColor"
          fill-rule="evenodd"
          stroke-width="1"
      />
    </svg>
  </span>
</template>
<script>
export default {
  props: {
      color: {
        type: String,
        default: "has-text-grey"
      },
      size: {
        type: Number,
        default: 16
      }
  }
};
</script>
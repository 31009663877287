<template>
  <span class="icon" :class="color">
    <svg :width="size" :height="size" fill="currentColor" class="bi bi-chevron-expand" viewBox="0 0 16 16">
      <path
          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708m0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708"
          stroke="currentColor"
          fill-rule="evenodd"
          stroke-width="1"
          fill="none"
      />
    </svg>
  </span>
</template>
<script>
export default {
  props: {
      color: {
        type: String,
        default: "has-text-grey"
      },
      size: {
        type: Number,
        default: 16
      }
  }
};
</script>